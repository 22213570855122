<template>
  <div class="not-found">
    <div class="container">
      <h1 class="not-found__title">Error 404</h1>
      <div class="not-found__text text">Page not found</div>
      <div class="not-found__btn">
        <ButtonBase @click="goToDashboard">
          Dashboard
        </ButtonBase>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isAuthUser']),
  },
  methods: {
    goToDashboard() {
      this.$router.push({ name: 'dashboard' });
    },
  },
};
</script>

<style lang="sass" scoped>
.not-found
  padding-top: 80px
  @include ms
    padding-top: 0

.not-found__text
  margin: 12px 0 64px 0

.not-found__btn
  display: flex
  justify-content: center
</style>
